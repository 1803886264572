/* eslint-disable */
import React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import { useContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/client';
import Promotions from '@/components/Promotions/Promotions';
import { useInfiniteQuery } from 'react-query';
import Api from '@/services/Api';
import Playlist from '@/components/Playlist/Playlist';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ArrowSmDownIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import DialogWishlist from '@/components/Dialog/DialogWishlist';
import { AppContext } from '@/contexts/app.context';
import { PROMOTION_URL, PLAYLIST_URL } from '@/utils/api-url';
import { ContentType } from '../@types';
import { PATH_PAGE } from '../routes/paths';

type Props = {
  promotions: any;
  playlists: any;
};

const Home: NextPage<Props> = ({ promotions, playlists }: Props) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const [session] = useSession();
  // @ts-ignore
  const userId = session ? session.user.id : '';
  const { isWishlistModalOpen, onCancelWishlistModal, onAcceptWishlistModal } =
    useContext(AppContext);

  const {
    data: playlistsData,
    hasNextPage,
    isFetching,
    error,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['playlists', userId],
    async ({ pageParam = 1 }) => {
      try {
        return await Api.get(
          `${PLAYLIST_URL}?page=${pageParam}&includeChannels=true`,
        );
      } catch (err) {
        if (err.status >= 500) {
          throw new Error('Error request');
        }
      }
    },
    {
      initialData: {
        pageParams: [1],
        pages: [playlists],
      },
      staleTime: 60000 * 5,
      getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    },
  );

  if (error) {
    push(PATH_PAGE.page500);
  }

  return (
    <>
      <Head>
        <title>TV Gratis | Butaca</title>
        <meta
          name="description"
          content="Original movies, documentaries, and television series in Spanish without a subscription and completely free."
        />
      </Head>
      <Promotions promotions={promotions} />
      {playlistsData.pages.length > 0 && (
        <div className="container mx-auto px-4 xl:px-0 pt-0 pb-8">
          <div className="space-y-8 divide-y-2 divide-white divide-opacity-10">
            {playlistsData.pages.map((page) =>
              page.docs.map((playlist) => (
                <Playlist key={playlist._id} playlist={playlist} />
              )),
            )}
          </div>
          {hasNextPage && (
            <div className="mt-8 flex justify-center">
              <button
                type="button"
                onClick={() => fetchNextPage()}
                className="transition-opacity ease-in-out duration-500 flex items-center font-semibold bg-white bg-opacity-10 pl-4 pr-3 py-2 rounded-md space-x-2 border border-white border-opacity-10 opacity-80 hover:opacity-100 text-sm"
              >
                {isFetching || isFetchingNextPage ? (
                  <div className="flex space-x-3">
                    <span>{t('loading')}...</span>
                  </div>
                ) : (
                  <>
                    <span> {t('loadMore')} </span>
                    <ArrowSmDownIcon className="w-6" />
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      )}
      <DialogWishlist
        show={isWishlistModalOpen}
        onCancel={onCancelWishlistModal}
        onAccept={onAcceptWishlistModal}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
  locale,
}) => {
  try {
    const [promotions, playlists] = await Promise.all([
      Api.get(
        `${PROMOTION_URL}?display_on=${ContentType.BROWSE}&includeChannels=true`,
        req,
      ),
      Api.get(`${PLAYLIST_URL}?page=1&includeChannels=true`, req),
    ]);

    return {
      props: {
        promotions,
        playlists,
        ...(await serverSideTranslations(locale, ['common', 'profile'])),
      },
    };
  } catch (err) {
    if (err.status === 404) {
      return {
        notFound: true,
      };
    }
    return {
      redirect: { permanent: false, destination: '/500' },
    };
  }
};

export default Home;
